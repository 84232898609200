import React, { useState, useEffect, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import { getAllTeams, getTeamById } from "../../services/teamService";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

const AllTeams = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const [teams, setTeams] = useState([]);

  const getAllTheTeams = async () => {
    const data = await getAllTeams();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'équipes à ce jour.</h1>
        </div>
      );
    }

    setTeams(data);
  };

  const GoToTeam = async (teamId) => {
    const team = await getTeamById(teamId);
    dispatch({
      type: "get_team",
      payload: team,
    });
    navigate("/app/updateTeamPage");
  };

  useEffect(() => {
    getAllTheTeams();
  }, []);

  return (
    <div className="box">
      <table className="table is-striped is-hoverable is-responsive">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>
              <abbr title="Team-name">Nom de l'équipe</abbr>
            </th>
            <th>
              <abbr title="Team-players">Nombre de joueurs</abbr>
            </th>
          </tr>
        </thead>

        <tbody>
          {teams.length !== 0 &&
            teams.teams.map((team) => (
              <tr key={team.name} style={{ height: "45px" }}>
                <td>{team.name}</td>
                <td>{team.listOfPlayer.length}</td>
                <td>
                  <button
                    className="button is-primary"
                    onClick={() => {
                      GoToTeam(team._id);
                    }}
                  >
                    <span className="icon is-medium">
                      <span className="fa-stack">
                        <i className="fas fa-circle fa-stack-2x"></i>
                      </span>
                    </span>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllTeams;
